import { createNextState } from '@reduxjs/toolkit';
import { CLEAR_STORE_IMAGE_ERRORS, UPDATE_STORE, UPDATE_STORE_IMAGE, UPLOAD_STORE_IMAGE_ERROR_CODE, STORE_IMAGE_FORMAT_WARNING, CLEAR_STORE_IMAGE_FORMAT_WARNING } from '../../../actions/marketplace/stores';

function getInitialState() {
  return {};
}

export default function listReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UPDATE_STORE_IMAGE: {
        const { id, imageSha1, loading } = payload;
        const image = draft[id].store.images.find(img => img.id === imageSha1);
        if (image) {
          image.loading = loading;
        }
        break;
      }
      case CLEAR_STORE_IMAGE_FORMAT_WARNING: {
        const { id, imageSha1 } = payload;
        const image = draft[id].store.images.find(img => img.id === imageSha1);
        if (image) {
          image.warning = null;
        }
        break;
      }
      case STORE_IMAGE_FORMAT_WARNING: {
        const { id, imageSha1 } = payload;
        const image = draft[id].store.images.find(img => img.id === imageSha1);
        if (image) {
          image.warning = true;
        }
        break;
      }
      case CLEAR_STORE_IMAGE_ERRORS: {
        const { id, imageSha1 } = payload;
        const image = draft[id].store.images.find(img => img.id === imageSha1);
        if (image) {
          image.errorCode = null;
        }
        break;
      }
      case UPLOAD_STORE_IMAGE_ERROR_CODE: {
        const { id, imageSha1, errorCode } = payload;
        const image = draft[id].store.images.find(img => img.id === imageSha1);
        if (image) {
          image.errorCode = errorCode;
          image.loading = false;
        }
        break;
      }
      case UPDATE_STORE: {
        const { id, loading, store, error } = payload;

        if (!draft[id]) {
          draft[id] = {};
        }

        draft[id].loading = loading;
        draft[id].error = error;

        if (store) {
          draft[id].store = store;
        }
        break;
      }
      default:
        return draft;
    }
  });
}
