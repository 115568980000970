/* eslint-disable arrow-body-style */
import { getStore as getStoreSelector } from '../../../selectors/marketplace/stores';
import {
  removeArtist as removeArtistService,
  addArtist as addArtistService,
  save as saveService,
  get as getService,
  uploadImage as uploadImageService,
  deleteImage as deleteImageService,
  setPrimaryImage as setPrimaryImageService,
} from '../../../../services/wallet/store';
import { on, off } from '../../ui/switches';

export const UPDATE_STORE_IMAGE = 'marketplace/stores/UPDATE_STORE_IMAGE';
export const updateStoreImage = ({ id, imageSha1, loading = false }) => ({
  type: UPDATE_STORE_IMAGE,
  payload: {
    id,
    imageSha1,
    loading,
  },
});

export const CLEAR_STORE_IMAGE_ERRORS = 'marketplace/stores/CLEAR_STORE_IMAGE_ERRORS';
export const clearStoreImageErrors = ({ id, imageSha1 }) => ({
  type: CLEAR_STORE_IMAGE_ERRORS,
  payload: {
    id,
    imageSha1,
  },
});

export const UPLOAD_STORE_IMAGE_ERROR_CODE = 'marketplace/stores/UPLOAD_STORE_IMAGE_ERROR_CODE';
export const uploadStoreImageErrorCode = ({ id, imageSha1, errorCode }) => ({
  type: UPLOAD_STORE_IMAGE_ERROR_CODE,
  payload: {
    id,
    imageSha1,
    errorCode,
  },
});

export const STORE_IMAGE_FORMAT_WARNING = 'marketplace/stores/STORE_IMAGE_FORMAT_WARNING';
export const storeImageFormatWarning = ({ id, imageSha1 }) => ({
  type: STORE_IMAGE_FORMAT_WARNING,
  payload: {
    id,
    imageSha1,
  },
});

export const CLEAR_STORE_IMAGE_FORMAT_WARNING = 'marketplace/stores/CLEAR_STORE_IMAGE_FORMAT_WARNING';
export const clearStoreImageFormatWarning = ({ id, imageSha1 }) => {
  return {
    type: CLEAR_STORE_IMAGE_FORMAT_WARNING,
    payload: {
      id,
      imageSha1,
    },
  };
};

export const UPDATE_STORE = 'marketplace/stores/UPDATE_STORE';
export const updateStore = ({ id, store, error, loading = false }) => ({
  type: UPDATE_STORE,
  payload: {
    id,
    store,
    error,
    loading,
  },
});

export const get = id => async (dispatch, getState) => {
  const store = getStoreSelector(getState(), { idstore: id });
  if (!store) {
    dispatch(updateStore({ id, loading: true }));
    try {
      const fetchedStore = await getService(id);
      dispatch(updateStore({ id, loading: false, store: fetchedStore }));
    } catch (err) {
      console.error(err);
      dispatch(
        updateStore({
          id,
          loading: false,
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  }
};

export const save = (idstore, values) => {
  return async dispatch => {
    dispatch(on(`save-store-${idstore}`));
    dispatch(updateStore({ id: idstore, loading: true }));
    const savedStore = await saveService(idstore, values);
    dispatch(updateStore({ id: idstore, loading: false, store: savedStore }));
    dispatch(off(`save-store-${idstore}`));
  };
};

export const removeArtist = (idstore, idartist) => {
  return async dispatch => {
    dispatch(on(`delete-store-artist-${idstore}-${idartist}`));
    dispatch(updateStore({ id: idstore, loading: true }));
    const store = await removeArtistService(idartist);
    dispatch(updateStore({ id: idstore, loading: false, store }));
    dispatch(off(`delete-store-artist-${idstore}-${idartist}`));
  };
};

export const addArtist = (idstore, idartist) => {
  return async dispatch => {
    dispatch(on(`add-store-artist-${idstore}`));
    dispatch(updateStore({ id: idstore, loading: true }));
    const store = await addArtistService(idartist);
    dispatch(updateStore({ id: idstore, loading: false, store }));
    dispatch(off(`add-store-artist-${idstore}`));
  };
};

export const uploadImage = (idstore, file, overridedImageId) => {
  return async (dispatch, getState) => {
    const store = getStoreSelector(getState(), { idstore });
    dispatch(updateStore({ store, id: idstore, loading: true }));
    dispatch(clearStoreImageFormatWarning({ id: idstore, imageSha1: overridedImageId }));
    const responseStatus = await uploadImageService(file, overridedImageId);
    const fetchedStore = await getService(idstore);
    dispatch(updateStore({ store: fetchedStore, id: idstore, loading: false }));
    // must be under dispatch(updateStore) because updateStore will override everything (including the errorCode)
    // (Should be refacto in the next iteration)
    if (responseStatus >= 400) {
      dispatch(uploadStoreImageErrorCode({ errorCode: responseStatus, id: idstore, imageSha1: overridedImageId }));
    }
  };
};

export const deleteImage = (idstore, id) => {
  return async (dispatch, getState) => {
    dispatch(on(id));
    const store = getStoreSelector(getState(), { idstore });
    dispatch(updateStore({ store, id: idstore, loading: true }));
    await deleteImageService(id);
    const fetchedStore = await getService(idstore);
    dispatch(updateStore({ store: fetchedStore, id: idstore, loading: false }));
    dispatch(off(id));
  };
};

export const setPrimaryImage = (idstore, id) => {
  return async (dispatch, getState) => {
    dispatch(on(id));
    const store = getStoreSelector(getState(), { idstore });
    dispatch(updateStore({ store, id: idstore, loading: true }));
    await setPrimaryImageService(id);
    const fetchedStore = await getService(idstore);
    dispatch(updateStore({ store: fetchedStore, id: idstore, loading: false }));
    dispatch(off(id));
  };
};
